import {SeasonalActionModel} from '../models/SeasonalActionModel';
import {StudentPlacementSearchModel} from './StudentPlacementModel';
import {AddressSearch} from './AddressSearch';
import {StudentPoolSearchModel} from './StudentPoolSearchModel';
import {OpportunitySearchModel} from './OpportunitySearchModel';
import {TimelineStepIndexModel} from './TimelineStepIndexModel';
import {SeasonalStudentModel} from '../models/SeasonalStudentModel';

export class StudentSearchModel implements IStudentSearchModel {
  onboardingStudentSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  onboardingSteps?: TimelineStepIndexModel[] | undefined;
  onboardingStepIds?: string[] | undefined;
  graduatingStudentSettingId?: string | undefined;
  graduatingStepsComplete?: number | undefined;
  graduatingPercent?: number | undefined;
  graduatingStepIds?: string[] | undefined;
  graduatingSteps?: TimelineStepIndexModel[] | undefined;
  isCompleted?: boolean | undefined;
  studentStatus?: string | undefined;
  studentTags?: string[] | undefined;
  dateCompleted?: Date | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  university?: string | undefined;
  semesterStart?: number | undefined;
  semesterEnd?: number | undefined;
  semesterYearEnd?: number | undefined;
  semesterNumberEnd?: number | undefined;
  semesterNumberStart?: number | undefined;
  semesterYearStart?: number | undefined;
  degreeEndIndex?: number | undefined;
  campus?: string | undefined;
  degree?: string | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;
  degreeMajor?: string | undefined;
  isAtRisk?: boolean | undefined;
  atRiskReasons?: string[] | undefined;
  atRiskContext?: string | undefined;
  icsStatus?: string | undefined;
  isGraduating?: boolean | undefined;
  isUniversityProgram?: boolean | undefined;
  isPreUniversity?: boolean | undefined;
  currentCompanyId?: string | undefined;
  currentCompanyName?: string | undefined;
  currentPipelineCompanyId?: string | undefined;
  currentPipelineCompanyName?: string | undefined;
  placementHistory?: StudentPlacementSearchModel[] | undefined;
  allPlacements?: StudentPlacementSearchModel[] | undefined;
  topPlacement?: StudentPlacementSearchModel | undefined;
  currentPools?: StudentPoolSearchModel[] | undefined;
  previousPools?: StudentPoolSearchModel[] | undefined;
  topPool?: StudentPoolSearchModel | undefined;
  topOpportunityStudent?: OpportunitySearchModel | undefined;
  opportunityStudents?: OpportunitySearchModel[] | undefined;
  seasonalActions?: SeasonalActionModel[] | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;

  constructor(data?: IStudentSearchModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.onboardingStudentSettingId = Data["onboardingStudentSettingId"];
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      if (Array.isArray(Data["onboardingSteps"])) {
        this.onboardingSteps = [] as any;
        for (let item of Data["onboardingSteps"])
          this.onboardingSteps!.push(TimelineStepIndexModel.fromJS(item));
      }
      if (Array.isArray(Data["onboardingStepIds"])) {
        this.onboardingStepIds = [] as any;
        for (let item of Data["onboardingStepIds"])
          this.onboardingStepIds!.push(item);
      }
      this.graduatingStudentSettingId = Data["graduatingStudentSettingId"];
      this.graduatingStepsComplete = Data["graduatingStepsComplete"];
      this.graduatingPercent = Data["graduatingPercent"];
      if (Array.isArray(Data["graduatingStepIds"])) {
        this.graduatingStepIds = [] as any;
        for (let item of Data["graduatingStepIds"])
          this.graduatingStepIds!.push(item);
      }
      if (Array.isArray(Data["graduatingSteps"])) {
        this.graduatingSteps = [] as any;
        for (let item of Data["graduatingSteps"])
          this.graduatingSteps!.push(TimelineStepIndexModel.fromJS(item));
      }
      this.isCompleted = Data["isCompleted"];
      this.studentStatus = Data["studentStatus"];
      if (Array.isArray(Data["studentTags"])) {
        this.studentTags = [] as any;
        for (let item of Data["studentTags"])
          this.studentTags!.push(item);
      }
      this.dateCompleted = Data["dateCompleted"] ? new Date(Data["dateCompleted"].toString()) : <any>undefined;
      this.locationAddress = Data["locationAddress"] ? AddressSearch.fromJS(Data["locationAddress"]) : <any>undefined;
      this.quickNotes = Data["quickNotes"];
      this.university = Data["university"];
      this.semesterStart = Data["semesterStart"];
      this.semesterEnd = Data["semesterEnd"];
      this.semesterYearEnd = Data["semesterYearEnd"];
      this.semesterNumberEnd = Data["semesterNumberEnd"];
      this.semesterNumberStart = Data["semesterNumberStart"];
      this.semesterYearStart = Data["semesterYearStart"];
      this.degreeEndIndex = Data["degreeEndIndex"];
      this.campus = Data["campus"];
      this.degree = Data["degree"];
      if (Array.isArray(Data["degreePoolId"])) {
        this.degreePoolId = [] as any;
        for (let item of Data["degreePoolId"])
          this.degreePoolId!.push(item);
      }
      this.degreePoolFirst = Data["degreePoolFirst"];
      this.degreeMajor = Data["degreeMajor"];
      this.isAtRisk = Data["isAtRisk"];
      if (Array.isArray(Data["atRiskReasons"])) {
        this.atRiskReasons = [] as any;
        for (let item of Data["atRiskReasons"])
          this.atRiskReasons!.push(item);
      }
      this.atRiskContext = Data["atRiskContext"];
      this.icsStatus = Data["icsStatus"];
      this.isGraduating = Data["isGraduating"];
      this.isUniversityProgram = Data["isUniversityProgram"];
      this.isPreUniversity = Data["isPreUniversity"];
      this.currentCompanyId = Data["currentCompanyId"];
      this.currentCompanyName = Data["currentCompanyName"];
      this.currentPipelineCompanyId = Data["currentPipelineCompanyId"];
      this.currentPipelineCompanyName = Data["currentPipelineCompanyName"];
      if (Array.isArray(Data["placementHistory"])) {
        this.placementHistory = [] as any;
        for (let item of Data["placementHistory"])
          this.placementHistory!.push(StudentPlacementSearchModel.fromJS(item));
      }
      if (Array.isArray(Data["allPlacements"])) {
        this.allPlacements = [] as any;
        for (let item of Data["allPlacements"])
          this.allPlacements!.push(StudentPlacementSearchModel.fromJS(item));
      }
      this.topPlacement = Data["topPlacement"] ? StudentPlacementSearchModel.fromJS(Data["topPlacement"]) : <any>undefined;
      if (Array.isArray(Data["currentPools"])) {
        this.currentPools = [] as any;
        for (let item of Data["currentPools"])
          this.currentPools!.push(StudentPoolSearchModel.fromJS(item));
      }
      if (Array.isArray(Data["previousPools"])) {
        this.previousPools = [] as any;
        for (let item of Data["previousPools"])
          this.previousPools!.push(StudentPoolSearchModel.fromJS(item));
      }
      this.topPool = Data["topPool"] ? StudentPoolSearchModel.fromJS(Data["topPool"]) : <any>undefined;
      this.topOpportunityStudent = Data["topOpportunityStudent"] ? OpportunitySearchModel.fromJS(Data["topOpportunityStudent"]) : <any>undefined;
      if (Array.isArray(Data["opportunityStudents"])) {
        this.opportunityStudents = [] as any;
        for (let item of Data["opportunityStudents"])
          this.opportunityStudents!.push(OpportunitySearchModel.fromJS(item));
      }
      if (Array.isArray(Data["seasonalActions"])) {
        this.seasonalActions = [] as any;
        for (let item of Data["seasonalActions"])
          this.seasonalActions!.push(SeasonalActionModel.fromJS(item));
      }
      if (Array.isArray(Data["seasonalStudent"])) {
        this.seasonalStudent = [] as any;
        for (let item of Data["seasonalStudent"])
          this.seasonalStudent!.push(SeasonalStudentModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): StudentSearchModel {
    data = typeof data === 'object' ? data : {};
    let result = new StudentSearchModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["onboardingStudentSettingId"] = this.onboardingStudentSettingId;
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    if (Array.isArray(this.onboardingSteps)) {
      data["onboardingSteps"] = [];
      for (let item of this.onboardingSteps)
        data["onboardingSteps"].push(item.toJSON());
    }
    if (Array.isArray(this.onboardingStepIds)) {
      data["onboardingStepIds"] = [];
      for (let item of this.onboardingStepIds)
        data["onboardingStepIds"].push(item);
    }
    data["graduatingStudentSettingId"] = this.graduatingStudentSettingId;
    data["graduatingStepsComplete"] = this.graduatingStepsComplete;
    data["graduatingPercent"] = this.graduatingPercent;
    if (Array.isArray(this.graduatingStepIds)) {
      data["graduatingStepIds"] = [];
      for (let item of this.graduatingStepIds)
        data["graduatingStepIds"].push(item);
    }
    if (Array.isArray(this.graduatingSteps)) {
      data["graduatingSteps"] = [];
      for (let item of this.graduatingSteps)
        data["graduatingSteps"].push(item.toJSON());
    }
    data["isCompleted"] = this.isCompleted;
    data["studentStatus"] = this.studentStatus;
    if (Array.isArray(this.studentTags)) {
      data["studentTags"] = [];
      for (let item of this.studentTags)
        data["studentTags"].push(item);
    }
    data["dateCompleted"] = this.dateCompleted ? this.dateCompleted.toISOString() : <any>undefined;
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["quickNotes"] = this.quickNotes;
    data["university"] = this.university;
    data["semesterStart"] = this.semesterStart;
    data["semesterEnd"] = this.semesterEnd;
    data["semesterYearEnd"] = this.semesterYearEnd;
    data["semesterNumberEnd"] = this.semesterNumberEnd;
    data["semesterNumberStart"] = this.semesterNumberStart;
    data["semesterYearStart"] = this.semesterYearStart;
    data["degreeEndIndex"] = this.degreeEndIndex;
    data["campus"] = this.campus;
    data["degree"] = this.degree;
    if (Array.isArray(this.degreePoolId)) {
      data["degreePoolId"] = [];
      for (let item of this.degreePoolId)
        data["degreePoolId"].push(item);
    }
    data["degreePoolFirst"] = this.degreePoolFirst;
    data["degreeMajor"] = this.degreeMajor;
    data["isAtRisk"] = this.isAtRisk;
    if (Array.isArray(this.atRiskReasons)) {
      data["atRiskReasons"] = [];
      for (let item of this.atRiskReasons)
        data["atRiskReasons"].push(item);
    }
    data["atRiskContext"] = this.atRiskContext;
    data["icsStatus"] = this.icsStatus;
    data["isGraduating"] = this.isGraduating;
    data["isUniversityProgram"] = this.isUniversityProgram;
    data["isPreUniversity"] = this.isPreUniversity;
    data["currentCompanyId"] = this.currentCompanyId;
    data["currentCompanyName"] = this.currentCompanyName;
    data["currentPipelineCompanyId"] = this.currentPipelineCompanyId;
    data["currentPipelineCompanyName"] = this.currentPipelineCompanyName;
    if (Array.isArray(this.placementHistory)) {
      data["placementHistory"] = [];
      for (let item of this.placementHistory)
        data["placementHistory"].push(item.toJSON());
    }
    if (Array.isArray(this.allPlacements)) {
      data["allPlacements"] = [];
      for (let item of this.allPlacements)
        data["allPlacements"].push(item.toJSON());
    }
    data["topPlacement"] = this.topPlacement ? this.topPlacement.toJSON() : <any>undefined;
    if (Array.isArray(this.currentPools)) {
      data["currentPools"] = [];
      for (let item of this.currentPools)
        data["currentPools"].push(item.toJSON());
    }
    if (Array.isArray(this.previousPools)) {
      data["previousPools"] = [];
      for (let item of this.previousPools)
        data["previousPools"].push(item.toJSON());
    }
    data["topPool"] = this.topPool ? this.topPool.toJSON() : <any>undefined;
    data["topOpportunityStudent"] = this.topOpportunityStudent ? this.topOpportunityStudent.toJSON() : <any>undefined;
    if (Array.isArray(this.opportunityStudents)) {
      data["opportunityStudents"] = [];
      for (let item of this.opportunityStudents)
        data["opportunityStudents"].push(item.toJSON());
    }
    if (Array.isArray(this.seasonalActions)) {
      data["seasonalActions"] = [];
      for (let item of this.seasonalActions)
        data["seasonalActions"].push(item.toJSON());
    }
    if (Array.isArray(this.seasonalStudent)) {
      data["seasonalStudent"] = [];
      for (let item of this.seasonalStudent)
        data["seasonalStudent"].push(item.toJSON());
    }
    return data;
  }
}

export interface IStudentSearchModel {
  onboardingStudentSettingId?: string | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  onboardingSteps?: TimelineStepIndexModel[] | undefined;
  onboardingStepIds?: string[] | undefined;
  graduatingStudentSettingId?: string | undefined;
  graduatingStepsComplete?: number | undefined;
  graduatingPercent?: number | undefined;
  graduatingStepIds?: string[] | undefined;
  graduatingSteps?: TimelineStepIndexModel[] | undefined;
  isCompleted?: boolean | undefined;
  studentStatus?: string | undefined;
  studentTags?: string[] | undefined;
  dateCompleted?: Date | undefined;
  locationAddress?: AddressSearch | undefined;
  quickNotes?: string | undefined;
  university?: string | undefined;
  semesterStart?: number | undefined;
  semesterEnd?: number | undefined;
  semesterYearEnd?: number | undefined;
  semesterNumberEnd?: number | undefined;
  semesterNumberStart?: number | undefined;
  semesterYearStart?: number | undefined;
  degreeEndIndex?: number | undefined;
  campus?: string | undefined;
  degree?: string | undefined;
  degreePoolId?: string[] | undefined;
  degreePoolFirst?: string | undefined;
  degreeMajor?: string | undefined;
  isAtRisk?: boolean | undefined;
  atRiskReasons?: string[] | undefined;
  atRiskContext?: string | undefined;
  icsStatus?: string | undefined;
  isGraduating?: boolean | undefined;
  isUniversityProgram?: boolean | undefined;
  isPreUniversity?: boolean | undefined;
  currentCompanyId?: string | undefined;
  currentCompanyName?: string | undefined;
  currentPipelineCompanyId?: string | undefined;
  currentPipelineCompanyName?: string | undefined;
  placementHistory?: StudentPlacementSearchModel[] | undefined;
  allPlacements?: StudentPlacementSearchModel[] | undefined;
  topPlacement?: StudentPlacementSearchModel | undefined;
  currentPools?: StudentPoolSearchModel[] | undefined;
  previousPools?: StudentPoolSearchModel[] | undefined;
  topPool?: StudentPoolSearchModel | undefined;
  topOpportunityStudent?: OpportunitySearchModel | undefined;
  opportunityStudents?: OpportunitySearchModel[] | undefined;
  seasonalActions?: SeasonalActionModel[] | undefined;
  seasonalStudent?: SeasonalStudentModel[] | undefined;
}