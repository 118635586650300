import {ICommonLoadActionType} from '../Common.actions';
import {createAction} from '@reduxjs/toolkit';
import {FormDraftsModel} from "../../types/models/FormDraftsModel";
import {FormDraftsEditModel} from "../../types/requests/FormDraftsEditModel";
import {FormDraftIdentifier} from "../../types/models/FormDraftIdentifier";

export interface IFormDraftListActionTypes extends ICommonLoadActionType {
  REFRESH: string;
}

export interface IFormDraftActionTypes {
  RESET: string;
  COMPLETE: string;
  SAVE: string;
  SAVED: string;
  LOAD: string;
  LOAD_ERROR: string;
  SAVE_ERROR: string;
  LOADED: string;
}

export const FORM_DRAFT: IFormDraftActionTypes = {
  RESET: 'formDraft/reset',
  COMPLETE: 'formDraft/complete',
  SAVE: 'formDraft/save',
  SAVED: 'formDraft/saveSuccess',
  SAVE_ERROR: 'formDraft/saveError',
  LOAD: 'formDraft/load',
  LOADED: 'formDraft/loaded',
  LOAD_ERROR: 'formDraft/loadError',
};

export const FORM_DRAFT_LIST: IFormDraftListActionTypes = {
  ERROR: 'formDraftList/error',
  LOAD: 'formDraftList/load',
  LOADED: 'formDraftList/loaded',
  REFRESH: 'formDraftList/refresh',
};

export const loadFormDrafts = createAction(FORM_DRAFT_LIST.LOAD);
export const loadFormDraftsSuccess = createAction<FormDraftIdentifier[]>(FORM_DRAFT_LIST.LOADED);
export const loadFormDraftsError = createAction<string>(FORM_DRAFT_LIST.ERROR);

export const resetFormDraftSelected = createAction(FORM_DRAFT.RESET);
export const formDraftLoad = createAction<FormDraftIdentifier>(FORM_DRAFT.LOAD);
export const formDraftLoadSuccess = createAction<FormDraftsModel | undefined>(FORM_DRAFT.LOADED);
export const formDraftLoadError = createAction<string>(FORM_DRAFT.LOAD_ERROR);
export const completeFormDraft = createAction<FormDraftIdentifier>(FORM_DRAFT.COMPLETE);
export const saveFormDraft = createAction<FormDraftsEditModel>(FORM_DRAFT.SAVE);
export const saveFormDraftSuccess = createAction(FORM_DRAFT.SAVED);
export const saveFormDraftError = createAction<string>(FORM_DRAFT.SAVE_ERROR);

export const saveActionTestDraft = createAction<any>('=========');
