import React, {useEffect, useState} from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ChangeRequestIcon from '@mui/icons-material/EditNotificationsOutlined';
import {Popover, Theme, ListItem, ListSubheader} from '@mui/material';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Scrollbar from '../../../@template/core/Scrollbar';
import clsx from 'clsx';
import StudentChangeRequestItem from './StudentChangeRequestItem';
import ContactChangeRequestItem from './ContactChangeRequestItem';
import {Fonts} from '../../../shared/constants/AppEnums';
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from 'react-redux';
import {
  selectStudentChangeRequestLoading,
  selectStudentChangeRequest,
  selectContactChangeRequest, selectContactChangeRequestLoading,
} from '../../../redux/changeRequest/Selectors';
import {StudentUpdatesSearchModel} from '../../../types/views/StudentUpdatesSearchModel';
import {hasItems} from '../../../@template/helpers/arrays';
import EmptyResult from '../../../@template/core/EmptyResult';
import {ContactUpdatesSearchModel} from '../../../types/views/ContactUpdatesSearchModel';
import {AddCircleOutline} from '@mui/icons-material';
import {useAppDispatch} from '../../../@template/utility/AppHooks';
import {studentExpandChangeRequestLoad} from '../../../redux/changeRequest/Actions';

const useStyles = makeStyles((theme: Theme) => ({
  crPopover: {
    '& .MuiPopover-paper': {
      width: 260,
      [theme.breakpoints.up('xs')]: {
        width: 300,
      },
      [theme.breakpoints.up('lg')]: {
        width: 380,
      },
    },
    '& .scroll-submenu': {
      maxHeight: 200,
      [theme.breakpoints.up('lg')]: {
        maxHeight: 380,
      },
      '& .MuiListSubheader-root': {
        lineHeight: '12px'
      }
    },
  },
  btnPopover: {
    borderRadius: 0,
    width: '100%',
    textTransform: 'capitalize',
  },
  notiBtn: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 56,
    fontSize: 16,
    borderRadius: 0,
    paddingLeft: '1rem',
    paddingRight: '1rem',
    color: theme.palette.grey[800],
    '&:hover, &:focus': {
      color: theme.palette.text.primary,
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.up('xs')]: {
      height: 70,
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      width: 'auto',
      borderLeftColor: theme.palette.grey[200],
      color: theme.palette.grey[400],
      '&:hover, &:focus': {
        color: theme.palette.text.primary,
      },
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: '0.2rem',
      paddingRight: '0.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
  notiIcon: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('lg')]: {
      fontSize: 30,
    },
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  badge: {
    marginRight: 8,
  },
  listItemRoot: {
    padding: '8px 10px',
  },
}));

interface ChangeRequestProps {}

const ChangeRequest: React.FC<ChangeRequestProps> = () => {
  const [
    anchorRequests,
    setAnchorRequests,
  ] = React.useState<HTMLButtonElement | null>(null);

  const dispatch = useAppDispatch();
  const studentChangeRequestDetail = useSelector(selectStudentChangeRequest);
  const studentChangeRequests = studentChangeRequestDetail?.result?.sort((a, b) => Date.parse(b?.updatePipelineLastUpdated?.toUTCString()??'') - Date.parse(a?.updatePipelineLastUpdated?.toUTCString()??''));
  const studentChangeRequestsLoading = useSelector(selectStudentChangeRequestLoading);

  const contactChangeRequestDetail = useSelector(selectContactChangeRequest);
  const contactChangeRequests = contactChangeRequestDetail?.result?.sort((a, b) => Date.parse(b?.updatePipelineLastUpdated?.toUTCString()??'') - Date.parse(a?.updatePipelineLastUpdated?.toUTCString()??''));
  const contactChangeRequestsLoading = useSelector(selectContactChangeRequestLoading);
  const [loadCount, setLoadCount] = useState<number | undefined>(10);
  const onClickRequestsButton = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorRequests(event.currentTarget);
  };

  useEffect(() => {
    if (studentChangeRequestDetail?.paging?.totalCount && studentChangeRequests?.length) {
      let remaining = studentChangeRequestDetail?.paging?.totalCount - studentChangeRequests?.length;
      if (remaining < 10) setLoadCount(remaining);
    }

  }, [studentChangeRequests, studentChangeRequestDetail]);

  const onClickExpandRequestsButton = () => {
    if (studentChangeRequests?.length){
      dispatch(studentExpandChangeRequestLoad(studentChangeRequests?.length + 10));
    }
    else dispatch(studentExpandChangeRequestLoad(20));
  };

  const classes = useStyles();

  const showNoResults = () => {
    return <EmptyResult title='No result' description='No pending update requests'/>;
  };

  const studentCount = studentChangeRequestDetail?.paging?.totalCount ? studentChangeRequestDetail.paging.totalCount : 0;
  const contactCount = contactChangeRequestDetail?.paging?.totalCount ? contactChangeRequestDetail.paging.totalCount : 0;

  return <>
    <IconButton
      className={clsx(classes.notiBtn, 'notiBtn')}
      aria-label='show 10 updates pending'
      color='inherit'
      onClick={onClickRequestsButton}
    >
      <Badge
        className={classes.badge}
        badgeContent={studentCount + contactCount}
        color='secondary'>
        <ChangeRequestIcon
          className={clsx(classes.notiIcon, 'notiIcon')}
        />
      </Badge>
      <Box
        sx={{ display: {  xs: 'block', sm: 'none' } }}
        ml={4}
        fontSize={16}
        fontWeight={Fonts.REGULAR}
        component='span'>
        Updates Pending
      </Box>
    </IconButton>

    <Popover
      anchorEl={anchorRequests}
      id='change-request'
      className={classes.crPopover}
      keepMounted
      open={Boolean(anchorRequests)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={() => setAnchorRequests(null)}>
      <Box>
        <Box px={5} py={3}>
          <Box component='h5' fontSize={16}>
            Updates Pending
          </Box>
        </Box>
        <Scrollbar className='scroll-submenu'>
          {!hasItems(studentChangeRequests) && !studentChangeRequestsLoading && !hasItems(contactChangeRequests) && !contactChangeRequestsLoading &&  showNoResults()}
          <List
            className={classes.list}
            // onClick={() => {
            //   setAnchorRequests(null);
            // }}
          >
            {hasItems(studentChangeRequests) && <ListSubheader>Students</ListSubheader>}
            {studentChangeRequests?.map((item: StudentUpdatesSearchModel, index) => (
              <StudentChangeRequestItem key={item.personId} item={item} anchorClick={()=>setAnchorRequests(null)} />
            ))}
            {!!studentChangeRequestDetail?.paging?.totalCount && studentChangeRequestDetail?.paging?.totalCount > 10 && studentChangeRequestDetail?.paging?.totalCount !== studentChangeRequests?.length && <ListItem className={classes.listItemRoot} >
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={onClickExpandRequestsButton}
                sx={{marginRight: '5px'}}
              >
                <AddCircleOutline />
              </IconButton>
              {loadCount} More
            </ListItem>}
            {hasItems(contactChangeRequests) && <ListSubheader>Contacts</ListSubheader>}
            {contactChangeRequests?.map((item: ContactUpdatesSearchModel, index) => (
              <ContactChangeRequestItem key={item.personId} item={item} anchorClick={()=>setAnchorRequests(null)} />
            ))}
            {!!contactChangeRequestDetail?.paging?.totalCount && contactChangeRequestDetail?.paging?.totalCount > 10 && <ListItem className={classes.listItemRoot}>
              <Box component='p'><AddCircleOutline />  {contactChangeRequestDetail?.paging?.totalCount - 10} More</Box>
            </ListItem>}
          </List>
        </Scrollbar>
      </Box>
    </Popover>
  </>;

};

export default ChangeRequest;
