import {all, call, put, takeEvery} from '@redux-saga/core/effects';
import {authLogOut} from '../auth/Actions';
import {
  emailTemplateFileListSuccess,
  LOCALITIES,
  localitiesError,
  localitiesLoadSuccess,
  SESSION,
  sessionLoadError,
  sessionLoadSuccess, tagsUpdateFile, tagsUpdateSystemFile,
} from './Actions';
import {apiConfig} from '../../config';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {CountryModel} from '../../types/models/CountryModel';
import {PlatformSettingsModel} from '../../types/models/PlatformSettingsModel';
import {hasItems} from '../../@template/helpers/arrays';
import {FileSettingsModel} from '../../types/models/FileSettingsModel';
import {messageDraftListLoad} from '../messaging/Actions';
import {loadFormDrafts} from "../formDrafts/Actions";


function* LocalitiesLoad() {
  console.log('SAGA - sessionSaga - LocalitiesLoad');
  try {
    const res = yield authApi.get(`${apiConfig.coreSettingsPath}/localities`);
    const locations = res.data.map((x: any) => CountryModel.fromJS(x));
    yield put(localitiesLoadSuccess(locations));
  } catch (error) {
    yield put(localitiesError(error));
    // Can't use the platform without settings
    console.log('localitiesLoad LOGOUT', error);
    yield put(authLogOut());
  }
}

function* SessionInitialise() {
  console.log('SAGA - sessionSaga - SessionInitialise');
  try {
    const sessionResult = yield authApi.get(`${apiConfig.coreAuthPath}/session`);
    const settingsData = PlatformSettingsModel.fromJS(sessionResult.data);
    // FILES
    let emailTemplates = settingsData.emailTemplates;
    if (emailTemplates && hasItems(emailTemplates)) {
      let fileLists = emailTemplates.map(template => {
        if (template.fileIdList && template.fileIdList.length > 0) {
          return template.fileIdList;
        } else {
          return [];
        }
      });
      let fileIdArray: string[] = [];
      fileIdArray = fileIdArray.concat(...fileLists);
      const filesResult = yield authApi.request({
        baseURL: apiConfig.filesUrl,
        url: `${apiConfig.filesPath}/session`,
        method: 'PUT',
        data: {
          'idList': fileIdArray
        }
      });
      const filesData = FileSettingsModel.fromJS(filesResult.data);
      if (filesData.tagListModel) {
        yield put(tagsUpdateFile(filesData.tagListModel.fileTags?? []));
        yield put(tagsUpdateSystemFile(filesData.tagListModel.systemTags?? []))
      }
      if (filesData.fileMetaModel) {
        yield put(emailTemplateFileListSuccess(filesData.fileMetaModel));
      }
    }
    yield put(messageDraftListLoad());
    yield put(loadFormDrafts());
    yield put(sessionLoadSuccess(settingsData));
  } catch (error) {
    yield put(sessionLoadError(error));
    // Can't use the platform without settings
    console.log('currentSessionLoad LOGOUT', error);
    yield put(authLogOut());
  }
}

export function* watchLocalitiesLoad(){
  yield takeEvery(LOCALITIES.LOAD, LocalitiesLoad);
}

export function* watchSessionInitialise(){
  yield takeEvery(SESSION.LOAD, SessionInitialise);
}

export default function* sessionSaga() {
  yield all ([
    call(watchLocalitiesLoad),
    call(watchSessionInitialise),
  ])
}