import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {
  FORM_DRAFT, FORM_DRAFT_LIST
} from './Actions';
import {FormDraftsModel} from "../../types/models/FormDraftsModel";
import {FormDraftIdentifier} from "../../types/models/FormDraftIdentifier";

export interface IFormDraftState {
  formDraftsList: FormDraftsModel[] | undefined;
  formDraftsListLoading: boolean;
  formDraftsListError: string | undefined;

  formDraft: FormDraftsModel | undefined;
  formDraftLoading: boolean;
  formDraftCompleting: boolean;
  formDraftSaving: boolean;
  formDraftSaveError: string | undefined;
  formDraftLoadError: string | undefined;
}

const initialState: IFormDraftState = {
  formDraftsList: [],
  formDraftsListLoading: false,
  formDraftsListError: undefined,

  formDraft: undefined,
  formDraftLoading: false,
  formDraftCompleting: false,
  formDraftSaving: false,
  formDraftSaveError: undefined,
  formDraftLoadError: undefined,
};

const formDraftReducer = createReducer(initialState, {
  [FORM_DRAFT_LIST.LOAD]: (state) => {
    state.formDraftsListLoading = true;
    state.formDraftsListError = undefined;
  },
  [FORM_DRAFT_LIST.ERROR]: (state, action: PayloadAction<string>) => {
    state.formDraftsList = [];
    state.formDraftsListError = action.payload;
    state.formDraftsListLoading = false;
  },
  [FORM_DRAFT_LIST.LOADED]: (state, action: PayloadAction<FormDraftIdentifier[]>) => {
    state.formDraftsList = action.payload;
    state.formDraftsListError = undefined;
    state.formDraftsListLoading = false;
  },
  [FORM_DRAFT.RESET]: (state) => {
    state.formDraft = undefined;
    state.formDraftLoadError = undefined;
  },
  [FORM_DRAFT.LOAD]: (state) => {
    state.formDraftLoading = true;
    state.formDraftLoadError = undefined;
  },
  [FORM_DRAFT.LOADED]: (state, action: PayloadAction<FormDraftsModel>) => {
    state.formDraft = action.payload;
    state.formDraftLoadError = undefined;
    state.formDraftLoading = false;
  },
  [FORM_DRAFT.COMPLETE]: (state) => {
    state.formDraftCompleting = true;
    state.formDraftSaveError = undefined;
  },
  [FORM_DRAFT.SAVE]: (state) => {
    state.formDraftSaving = true;
    state.formDraftSaveError = undefined;
  },
  [FORM_DRAFT.SAVED]: (state) => {
    state.formDraftCompleting = false;
    state.formDraftSaving = false;
    state.formDraftSaveError = undefined;
  },
  [FORM_DRAFT.SAVE_ERROR]: (state, action: PayloadAction<string>) => {
    state.formDraftCompleting = false;
    state.formDraftSaving = false;
    state.formDraftSaveError = action.payload;
  },
});

export default formDraftReducer;
