import {EducationOnboardingModel} from './EducationOnboardingModel';
import {ClientPartnerTagModel} from './ClientPartnerTagModel';
import {EducationOnboardingHistoryModel} from './EducationOnboardingHistoryModel';
import {EducationDetailModelEducationStatus} from './Enums';

export class EducationDetailModel implements IEducationDetailModel {
  onboardingEducationSettingId?: string | undefined;
  educationOnboardingSetting?: EducationOnboardingModel | undefined;
  educationOnboardingHistory?: EducationOnboardingHistoryModel[] | undefined;
  educationStatus?: EducationDetailModelEducationStatus | undefined;
  educationTags?: ClientPartnerTagModel[] | undefined;
  isActive?: boolean | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  institutionType?: string | undefined;
  educationSummary?: string | undefined;
  mouStartDate?: Date | undefined;
  mouEndDate?: Date | undefined;
  mouTerm?: number | undefined;
  reportingFrequency?: number | undefined;
  internKpi?: number | undefined;
  focusAreas?: string[] | undefined;
  strategicGoals?: string | undefined;

  constructor(data?: IEducationDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.onboardingEducationSettingId = Data["onboardingEducationSettingId"];
      this.educationOnboardingSetting = Data["educationOnboardingSetting"] ? EducationOnboardingModel.fromJS(Data["educationOnboardingSetting"]) : <any>undefined;
      if (Array.isArray(Data["educationOnboardingHistory"])) {
        this.educationOnboardingHistory = [] as any;
        for (let item of Data["educationOnboardingHistory"])
          this.educationOnboardingHistory!.push(EducationOnboardingHistoryModel.fromJS(item));
      }
      this.educationStatus = Data["educationStatus"];
      if (Array.isArray(Data["educationTags"])) {
        this.educationTags = [] as any;
        for (let item of Data["educationTags"])
          this.educationTags!.push(ClientPartnerTagModel.fromJS(item));
      }
      this.isActive = Data["isActive"];
      this.onboardingStepsComplete = Data["onboardingStepsComplete"];
      this.onboardingPercent = Data["onboardingPercent"];
      this.institutionType = Data["institutionType"];
      this.educationSummary = Data["educationSummary"];
      this.mouStartDate = Data["mouStartDate"] ? new Date(Data["mouStartDate"].toString()) : <any>undefined;
      this.mouEndDate = Data["mouEndDate"] ? new Date(Data["mouEndDate"].toString()) : <any>undefined;
      this.mouTerm = Data["mouTerm"];
      this.reportingFrequency = Data["reportingFrequency"];
      this.internKpi = Data["internKpi"];
      if (Array.isArray(Data["focusAreas"])) {
        this.focusAreas = [] as any;
        for (let item of Data["focusAreas"])
          this.focusAreas!.push(item);
      }
      this.strategicGoals = Data["strategicGoals"];
    }
  }

  static fromJS(data: any): EducationDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new EducationDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["onboardingEducationSettingId"] = this.onboardingEducationSettingId;
    data["educationOnboardingSetting"] = this.educationOnboardingSetting ? this.educationOnboardingSetting.toJSON() : <any>undefined;
    if (Array.isArray(this.educationOnboardingHistory)) {
      data["educationOnboardingHistory"] = [];
      for (let item of this.educationOnboardingHistory)
        data["educationOnboardingHistory"].push(item.toJSON());
    }
    data["educationStatus"] = this.educationStatus;
    if (Array.isArray(this.educationTags)) {
      data["educationTags"] = [];
      for (let item of this.educationTags)
        data["educationTags"].push(item.toJSON());
    }
    data["isActive"] = this.isActive;
    data["onboardingStepsComplete"] = this.onboardingStepsComplete;
    data["onboardingPercent"] = this.onboardingPercent;
    data["institutionType"] = this.institutionType;
    data["educationSummary"] = this.educationSummary;
    data["mouStartDate"] = this.mouStartDate ? this.mouStartDate.toISOString() : <any>undefined;
    data["mouEndDate"] = this.mouEndDate ? this.mouEndDate.toISOString() : <any>undefined;
    data["mouTerm"] = this.mouTerm;
    data["reportingFrequency"] = this.reportingFrequency;
    data["internKpi"] = this.internKpi;
    if (Array.isArray(this.focusAreas)) {
      data["focusAreas"] = [];
      for (let item of this.focusAreas)
        data["focusAreas"].push(item);
    }
    data["strategicGoals"] = this.strategicGoals;
    return data;
  }
}

export interface IEducationDetailModel {
  onboardingEducationSettingId?: string | undefined;
  educationOnboardingSetting?: EducationOnboardingModel | undefined;
  educationOnboardingHistory?: EducationOnboardingHistoryModel[] | undefined;
  educationStatus?: EducationDetailModelEducationStatus | undefined;
  educationTags?: ClientPartnerTagModel[] | undefined;
  isActive?: boolean | undefined;
  onboardingStepsComplete?: number | undefined;
  onboardingPercent?: number | undefined;
  institutionType?: string | undefined;
  educationSummary?: string | undefined;
  mouStartDate?: Date | undefined;
  mouEndDate?: Date | undefined;
  mouTerm?: number | undefined;
  reportingFrequency?: number | undefined;
  internKpi?: number | undefined;
  focusAreas?: string[] | undefined;
  strategicGoals?: string | undefined;
}